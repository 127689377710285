import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { useAppSelector } from 'redux-rtk';
import { LocationOrigin } from 'types';

import { RequestStatus } from '../../constants';
import { I18n } from '../../i18n';
import { PrimaryButton } from '../common/Buttons';
import { Modal } from '../Modal/Modal';

interface GPSInstructionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GPSInstructionsModal = ({
  isOpen,
  onClose,
}: GPSInstructionsModalProps) => {
  const requestStatus = useAppSelector(
    state => state.user.requests.userPositionThunkStatus,
  );
  const userLocationOrigin = useAppSelector(
    state => state.user.data.currentLocation.origin,
  );

  useEffect(() => {
    if (userLocationOrigin === LocationOrigin.Browser) {
      onClose();
    }
  }, [userLocationOrigin]);

  return (
    <Modal isOpen={isOpen} CardSx={{ minHeight: 'auto' }}>
      <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
        {I18n.t('gpsPermissionModal.title')}
      </Typography>
      <Typography variant="body1">
        {I18n.t('gpsPermissionModal.body.0', {
          appName: I18n.t('common.appName'),
        })}
        <a
          href="https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting"
          target="blank"
        >
          {' '}
          {I18n.t('gpsPermissionModal.body.1')}{' '}
        </a>
        {I18n.t('gpsPermissionModal.body.2')}
        <a href=""> {I18n.t('gpsPermissionModal.body.3')} </a>
        {I18n.t('gpsPermissionModal.body.4', {
          appName: I18n.t('common.appName'),
        })}
      </Typography>
      <PrimaryButton
        isLoading={requestStatus === RequestStatus.Pending}
        label={I18n.t('common.gotIt')}
        sx={{ width: '100%', mt: 2 }}
        onClick={onClose}
      />
    </Modal>
  );
};

import { Theme, ThemeOptions } from "@mui/material";

export const getThemedComponents = (theme: Theme) => ({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableTouchRipple: true,
        disableElevation: true,
        disableRipple: true, 
      },
      variants: [
        {
          props: { variant: "textGrayed" },
          style: {
            borderRadius: 16,
            "&:hover, &:focus": {
              backgroundColor: theme.palette.grey[50],
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            '&:hover, &:focus, &.Mui-focusVisible': {
              boxShadow: 1,
              transform: 'scale(1.01)',
              backgroundColor: theme.palette.primary.dark,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 50,
        },
        sizeSmall: {
          fontSize: "12px",
        },
        sizeMedium: {
          fontSize: "14px",
        },
        sizeLarge: {
          fontSize: "16px",
        },
        text: {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: theme.palette.grey[50],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: theme.palette.grey[200],
        },
        root: {
          "& .MuiSwitch-thumb": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[200],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
        },
      },
      defaultProps: {
        arrow: true,
        placement: "top",
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 50,
        },
      },
    }
  },
} as ThemeOptions);

// Module augmentation for extended components
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    textGrayed: true;
  }
}
